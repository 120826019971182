import logo from './logo.svg';
import './App.css';
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@mui/material/MobileStepper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

const images = [
  'https://cdn.kodekong.com/classicpizzabristol.com/bbq-chicken-ranch.jpg',
  'https://cdn.kodekong.com/classicpizzabristol.com/salad-and-wrap-2.jpg',
  'https://cdn.kodekong.com/classicpizzabristol.com/salad-and-wrap-1.jpg',
  'https://cdn.kodekong.com/classicpizzabristol.com/Classic+Pizza.jpg',
  'https://cdn.kodekong.com/classicpizzabristol.com/Classic+1.png',
  'https://cdn.kodekong.com/classicpizzabristol.com/Classic+6.jpg',
  'https://cdn.kodekong.com/classicpizzabristol.com/Classic+2.png',
  'https://cdn.kodekong.com/classicpizzabristol.com/Classic+4.png',
  'https://cdn.kodekong.com/classicpizzabristol.com/Classic+5.png'
];
const imagesPerSlide = 3;

function SwipeableImages() {

  const maxSteps = Math.ceil(images.length / imagesPerSlide);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  /**
   * 
   * @param {MouseEvent} e 
   */
  const handleClick = (e) => {
    var x = document.querySelector('#imageCard')
    var y = x.querySelector('.MuiCardMedia-root')
    y.src = e.target.src;
    x.classList.remove('hidden');
  };

  return (
    <Box
    sx={{flexGrow: 1}}
    key="1">
      <Typography>Images</Typography>
      <SwipeableViews
        axis="x"
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {
          function () {
            var components = [];
            for(var i = 0; i < images.length; i+=imagesPerSlide) {
              components.push(
                <Box
                  component="div"
                  aria-keyshortcuts=''
                  key={i}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                    width: '100%'
                  }}
                >
                  {images.slice(i, i+imagesPerSlide).map((path, index) => {
                    return <Box
                      key={"Parent:" + path}
                      sx={{
                        display: 'inline-block',
                        width: `calc(100% / ${imagesPerSlide})`,
                        padding: '5px',
                        overflow: 'hidden'
                      }}
                    >
                      <Box
                        component="img"
                        key={path}
                        sx={{
                          width: '100%',
                          objectFit: 'scale-down'
                        }}
                        src={path}
                        onClick={handleClick}
                      />
                    </Box>
                  })}
                </Box>
              );
            }
            return components;
          }()
        }
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    </Box>
  );
};

function App() {
  return (
    <div className="App">
      <div className="row">
        <h2>
          <strong>
            Call us
            <br/>
            (401) 253-1871
          </strong>
        </h2>
        <h1>
          <img src="https://cdn.kodekong.com/classicpizzabristol.com/image-asset.png"/>
        </h1>
        <h2>
          349 Metacom Ave.
          <br/>
          &nbsp;Bristol, RI
        </h2>
      </div>
      <hr/>
      <div className="row">
        <div className="row">
          <a
            href="https://www.toasttab.com/classic-pizza-grill-bristol-349-metacom-avenue/v3/?mode=fulfillment"
            target="_blank"
            className="button-style"
          >
            ORDER ONLINE
          </a>
          <a
            href="https://cdn.kodekong.com/classicpizzabristol.com/documents/Classic-Pizza-Menu-Mar-2024.pdf"
            target="_blank"
            className="button-style"
          >
            VIEW THE MENU
          </a>
        </div>
        <div>
          <h3>
            Hours
          </h3>
          <p>
            Sunday-Wednesday
            <br/>
            11AM - 10PM
          </p>
          <p>
            Thursday
            <br/>
            11AM - 11PM
          </p>
          <p>
            <br/>
            Friday & Saturday
            <br/>
            11AM - Midnight
          </p>
          <br/>
          <strong>WE DELIVER!</strong>
        </div>
        <div className="row">
          <a
            href="https://www.instagram.com/classicpizzabristolri"
            target="_blank"
            className="button-style"
          >
            Instagram
          </a>
          <a
            href="https://www.facebook.com/classicbristol"
            target="_blank"
            className="button-style"
          >
            Facebook
          </a>
        </div>
      </div>
      <hr/>
      {/* This is a photo slider element */}
      <SwipeableImages />
      <Card
        id="imageCard"
        className="hidden"
        sx={{
          position: "fixed",
          left: "5vw",
          width: "90vw",
          top: "5vh",
          bottom: "5vh",
          backgroundColor: "#000000a6"
        }}
        onClick={
          /**
           * 
           * @param {MouseEvent} e 
           */
          function (e) {
            e.currentTarget.classList.add("hidden");
          }
        }
      >

        <CardMedia
          component="img"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "scale-down"
          }}
        >
        </CardMedia>
      </Card>
      <hr/>
      <div className="row">
        <h2>Apply Now!</h2>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSezxrT59XDGMMVkBWpoVTSa0YAIkLt4DdkF6ywwvf6hg5Kgnw/viewform?embedded=true" width="640" height="2132" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
      </div>
    </div>
  );
}

export default App;
